/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/common';
import ogimage from '../images/locations/locations-og-image.jpg';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';
import MobileLocations from '../components/common/MobileLocations';
import DesktopLocations from '../components/common/DesktopLocations';

export default function Locations() {
    const { t } = useTranslation()

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title="About - Witbe">
            <SEO
                title="Locations - Witbe"
                ogDescription="Explore Witbe's global reach. Discover our international offices, local contact details, and exciting career opportunities in your region."
                description="Explore Witbe's global reach. Discover our international offices, local contact details, and exciting career opportunities in your region."
                image={ogimage}
            />

            {width < breakpoint ? ( <MobileLocations /> ) : ( <DesktopLocations /> )}
        </Layout>
    );
}
