/* eslint-disable */
import React from 'react';
// import Button from '@witbe/ui-react-components/dist/Button';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faChevronRight } from '@witbe/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import Banner from '../../images/locations/locations-hero.jpg';
import ogimageMobile from '../../images/locations/locations-hero-mobile.jpg';
import { useOpenContactModal, useViewport } from '../../hooks';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import homeStyles from '../../pages/styles/newHome.module.scss';
import styles from './styles/locations.module.scss';
import aboutStyles from '../../pages/styles/about.module.scss';
import careerStyles from '../../pages/styles/newCareers.module.scss';
import nanterre from '../../images/locations/icons/nanterre.svg';
import longIsland from '../../images/locations/icons/long_island.svg';
import paris from '../../images/locations/icons/paris.svg';
import nyc from '../../images/locations/icons/nyc.svg';
import london from '../../images/locations/icons/london.svg';
import sanfran from '../../images/locations/icons/san_francisco_bay.svg';
import dubai from '../../images/locations/icons/dubai.svg';
import denver from '../../images/locations/icons/denver.svg';
import lisbon from '../../images/locations/icons/lisbon.svg';
import montreal from '../../images/locations/icons/montreal.svg';
import singapore from '../../images/locations/icons/singapore.svg';
import phoenix from '../../images/locations/icons/phoenix.svg';
import productStyles from '../../pages/styles/newProducts.module.scss';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { LOCATIONS } = MODAL_SOURCE_BUTTONS;

export default function DesktopLocations() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpointTablets = 821;

    const openContactModal = useOpenContactModal(LOCATIONS);

    return (
        <>
            <div id={styles.mainImageWrapper}>
                {width < breakpointTablets ?
                    <div className={techStyles.mainImageTextWrapper}>
                        <div className={techStyles.mainImageLeft}>
                            <div className={careerStyles.mainImageContentMobile}>
                                <h1 className={styles.mainImageTitle}>{t('siteMetadata.locations.title')}</h1>
                                <p className={styles.mainImageSub}>
                                <p className={styles.mainImageSubInline}>{t('siteMetadata.locations.description')}</p>
                                <p className={styles.mainImageSubInlineLinkTablets}>
                                <a href="https://www.witbe.net/careers/">
                                    {t('siteMetadata.locations.description1')}
                                </a>
                            </p>
                                <p className={styles.mainImageSubInline}>{t('siteMetadata.locations.description2')}</p>
                            </p>
                                <div className={techStyles.learnMoreBuyButtons}>
                                    <Link
                                        to='/locations/'
                                        className={techStyles.blueButtonMobile + ' ' + styles.buyButton}
                                        onClick={openContactModal}
                                    >
                                        {t('siteMetadata.home.contactUs')}
                                    </Link>
                                    <Link
                                        to='/careers/'
                                        className={techStyles.cardLearnMoreInline}
                                    >
                                        {t('siteMetadata.locations.careers')}
                                    </Link>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.mainImageRight}>
                            <div className={styles.rightImageWrapper}>
                                <img src={ogimageMobile} alt='Banner' />
                            </div>
                        </div>
                    </div>
                :
                    <div className={styles.mainImageTextWrapper}>
                        <img src={Banner} id={styles.mainImg} alt='Banner' />
                        <div className={styles.mainImageContent}>
                            <h1 className={styles.mainImageTitle}>{t('siteMetadata.locations.title')}</h1>
                            <p className={styles.mainImageSub}>
                                <p className={styles.mainImageSubInline}>{t('siteMetadata.locations.description')}</p>
                                <p className={styles.mainImageSubInlineLink}>
                                <a href="https://www.witbe.net/careers/">
                                    {t('siteMetadata.locations.description1')}
                                </a>
                            </p>
                                <p className={styles.mainImageSubInline}>{t('siteMetadata.locations.description2')}</p>
                            </p>
                            <div className={techStyles.learnMoreBuyButtons}>
                                <Link
                                    to='/locations/'
                                    className={homeStyles.transparentButton + ' ' + homeStyles.buyButton}
                                    onClick={openContactModal}
                                >
                                    {t('siteMetadata.home.contactUs')}
                                </Link>
                                <div className={homeStyles.cardLearnMoreInlineContainer}>
                                    <Link
                                        to='/careers/'
                                        className={techStyles.cardLearnMoreInline}
                                    >
                                        {t('siteMetadata.locations.careers')}
                                    </Link>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={homeStyles.chevronRightWhite}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <section id={styles.quadColSection}>
                <ul id={styles.quadColList}>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={nanterre} className={styles.itemIcon} alt='Nanterre' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>
                                <p className={styles.itemAddress}>{t('siteMetadata.locations.nanterre')}</p>
                                <p className={styles.itemAddress}>{t('siteMetadata.locations.nanterre1')}</p>
                            </h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemSubtitle}>
                                    {t('siteMetadata.locations.nanterreSub')}
                                </p>
                            </div>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nanterreAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nanterreAddress1')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nanterreAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nanterreAddress3')}
                                </p>
                            </div>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemContactInfo}>
                                    {t('siteMetadata.locations.nanterrePhone')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={longIsland} className={styles.itemIcon} alt='Long Island' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>
                                <p className={styles.itemAddress}>{t('siteMetadata.locations.longIsland')}</p>
                                <p className={styles.itemAddress}>{t('siteMetadata.locations.longIsland1')}</p>
                            </h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemSubtitle}>
                                    {t('siteMetadata.locations.longIslandSub')}
                                </p>
                            </div>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.longIslandAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.longIslandAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.longIslandAddress3')}
                                </p>
                            </div>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemContactInfo}>
                                    {t('siteMetadata.locations.longIslandPhone')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={paris} className={styles.itemIcon} alt='Paris' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.paris')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.parisAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.parisAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.parisAddress3')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={nyc} className={styles.itemIcon} alt='NYC' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.nyc')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nycAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nycAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.nycAddress3')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={sanfran} className={styles.itemIcon} alt='San Francisco Bay' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.sanfran')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.sanfranAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.sanfranAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.sanfranAddress3')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.sanfranAddress4')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={denver} className={styles.itemIcon} alt='Denver' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.denver')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.denverAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.denverAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.denverAddress3')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.denverAddress4')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={phoenix} className={styles.itemIcon} alt='Phoenix' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.phoenix')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.phoenixAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.phoenixAddress1')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.phoenixAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.phoenixAddress4')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={montreal} className={styles.itemIcon} alt='Montreal' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.montreal')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.montrealAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.montrealAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.montrealAddress3')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.montrealAddress4')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={london} className={styles.itemIcon} alt='London' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.london')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.londonAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.londonAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.londonAddress3')}
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={lisbon} className={styles.itemIcon} alt='Lisbon' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.lisbon')}</h3>
                            <div className={styles.itemParagraph}>
                            <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.lisbonAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.lisbonAddress1')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.lisbonAddress2')}
                                </p>
                                {/* <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.lisbonAddress3')}
                                </p> */}
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={dubai} className={styles.itemIcon} alt='Dubai' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.dubai')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.dubaiAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.dubaiAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.dubaiAddress3')}
                                </p>
                                {/* <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.dubaiAddress4')}
                                </p> */}
                            </div>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.itemThumb}>
                            <img src={singapore} className={styles.itemIcon} alt='Singapore' />
                        </div>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>{t('siteMetadata.locations.singapore')}</h3>
                            <div className={styles.itemParagraph}>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.singaporeAddress')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.singaporeAddress1')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.singaporeAddress2')}
                                </p>
                                <p className={styles.itemAddress}>
                                    {t('siteMetadata.locations.singaporeAddress3')}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            <section id={productStyles.blueSection}>
                <h2 id={productStyles.blueSectionTitleFullWidth}>
                    {t('siteMetadata.locations.interested')}
                </h2>
                <div className={productStyles.blueSectionText}>
                    <p>{t('siteMetadata.locations.interestedSub')}</p>
                </div>
                <div className={homeStyles.witboxButtons}>
                    <Link
                        to='/locations/'
                        className={homeStyles.whiteButton + ' ' + homeStyles.buyButton}
                        onClick={openContactModal}
                    >
                        {t('siteMetadata.home.contactUs')}
                    </Link>
                </div>
            </section>
        </>
    );
}
